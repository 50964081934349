<!-- 消息公告 -->
<template>
  <div id="Message">
    <van-nav-bar
      :title="$t('m_mime.xxgg')"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <i v-if="active == 0" class="iconfont icon-caidan"></i>
      </template>
    </van-nav-bar>
    <van-tabs @click="tabChange" v-model="active">
      <van-tab :title="$t('m_annoucements.xx')">
        <div
          class="contxt-item"
          @click="readMsg(item, index)"
          v-for="(item, index) in msgList"
          :key="index"
        >
          <span :class="{ unRead: item.status == '0' }">
            <i
              class="iconfont icon-tongzhi"
              :class="{ 'unRead-item': item.status == '0' }"
            ></i>
            {{ item.title }}</span
          >
          <span :class="{ unRead: item.status == '0' }">{{
            item.create_time.substring(0, 10)
          }}</span>
        </div>
        <van-empty v-if="msgList.length <= 0" :description="$t('m_annoucements.zwsj')" />
      </van-tab>
      <van-tab :title="$t('m_annoucements.gg')">
        <div
          class="contxt-item"
          @click="readMsg(item, index)"
          v-for="(item, index) in anList"
          :key="index"
        >
          <span :class="{ unRead: item.status == '0' }">
            <i
              class="iconfont icon-tongzhi"
              :class="{ 'unRead-item': item.status == '0' }"
            ></i>
            {{ item.title }}</span
          >
          <span :class="{ unRead: item.status == '0' }">{{
            item.create_time.substring(0, 10)
          }}</span>
        </div>
        <van-empty v-if="anList.length <= 0" :description="$t('m_annoucements.zwsj')" />
      </van-tab>
    </van-tabs>
    <!-- 查看我的收款账户 -->
    <van-popup
      class="popup-msg"
      v-model="showMsg"
      position="bottom"
      closeable
      close-icon-position="top-right"
      :style="{ height: '100%' }"
    >
      <!-- 头部 -->
      <div class="scroll-header">
        <div class="title">{{$t('message.xxxq')}}</div>
      </div>
      <h1>{{ currentMsg.title }}</h1>
      <p class="time">
        {{$t('message.fbsj')}}：{{ currentMsg.create_time.substring(0, 10) }}
      </p>
      <p>{{ currentMsg.content }}</p>
    </van-popup>
    <van-action-sheet
      :cancel-text="$t('common.cancel')"
      v-model="show"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { getMsg, readOne, getAnnotation, readAll, msgDel } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      active: 0,
      show: false, // 右上角操作弹出
      showMsg: false,
      msgList: [],
      anList: [],
      currentMsg: {
        title: "--",
        create_time: "--",
        content: "--",
      },
      actions: [
        { name: this.$t('message.qbbjwyd'), value: 0 },
        { name: this.$t('message.delAll'), value: 1 },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getMsgShow();
  },
  methods: {
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /* 选项卡切换 */
    tabChange(e) {
      console.log(e);
      if (e === 0) {
        this.getMsgShow();
      } else if (e === 1) {
        this.getAnnotation();
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      console.log("抽屉");
      this.show = true;
    },
    /* 单个已读 */
    readOneFun(index) {
      readOne({ id: this.currentMsg.id }).then((res) => {
        console.log("已读");
        this.msgList[index].status = "1";
      });
    },
    /* 阅读消息 */
    readMsg(item, index) {
      this.currentMsg = item;
      if (item.status == 0) {
        // 未读
        this.readOneFun(index);
      }
      this.showMsg = true;
    },
    /* 选择操作 */
    onSelect(e) {
      console.log(e);
      if (e.value == 0) {
        // 全部已读
        this.readAllFun();
      } else if (e.value == 1) {
        // 全部删除
        this.msgDelFun();
      }
    },
    /* 获取公告 */
    getAnnotation() {
      this.loadingCom();
      getAnnotation().then((res) => {
        Toast.clear();
        this.anList = res.data;
      });
    },
    /* 获取消息 */
    getMsgShow() {
      this.loadingCom();
      getMsg().then((res) => {
        Toast.clear();
        this.msgList = res.data;
      });
    },
    /* 全部删除 */
    msgDelFun() {
      this.loadingCom();
      msgDel().then((res) => {
        Toast.clear();
        Toast.success(this.$t('m_cue.czcg'));
        this.msgList = [];
        this.show = false;
      });
    },
    /* 全部已读 */
    readAllFun() {
      this.loadingCom();
      readAll().then((res) => {
        Toast.clear();
        Toast.success(this.$t('m_cue.czcg'));
        this.show = false;
        this.getMsgShow();
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

.van-tabs {
  /deep/.van-tabs__nav--line {
    background-color: #373430;
  }

  /deep/.van-tab {
    color: #ffffff;
  }
  /deep/.van-tab--active {
    color: #eecb77;
  }

  /deep/.van-tabs__line {
    background-color: #eecb77;
  }
}

#Message .contxt-item i.unRead-item {
  position: relative;
}

#Message .contxt-item i.unRead-item:after {
  content: "";
  width: 0.12rem;
  height: 0.12rem;
  border-radius: 50%;
  position: absolute;
  top: -0.0725rem;
  right: -1px;
  background-color: #f05b54;
}

#Message {
  width: 100%;
  height: 100%;
  background-color: #222222;

  .contxt-item span.unRead {
    color: #eecb77;
  }

  .contxt-item {
    width: 95%;
    height: 1rem;
    margin: 0 auto;
    position: relative;
    font-size: 0;

    i {
      width: 1.35rem;
      height: 1.35rem;
      font-size: 0.32rem;
    }

    span {
      font-size: 0.3rem;
      color: #b7b7b7;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      height: 100%;
      line-height: 1rem;
    }

    span:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span:last-child {
      text-align: right;
    }
  }
}

.popup-msg {
  box-sizing: border-box;
  background-color: #222;

  h1 {
    width: 100%;
    font-size: 0.36rem;
    color: #b7b7b7;
    font-weight: 400;
    text-align: center;
    padding: 0.2rem;
    box-sizing: border-box;
  }

  .time {
    text-align: center;
    width: 90%;
    color: #b4b4b4;
    padding: 0 5% 0.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid #333333;
  }

  p {
    width: 90%;
    margin: 0.2rem auto;
    font-size: 0.26rem;
    line-height: 0.4rem;
    word-wrap: break-word;
    word-break: normal;
    font-size: 0.28rem;
    color: #b7b7b7;
    box-sizing: border-box;
  }
}

.scroll-header {
  height: 40px;
  line-height: 40px;
  background-image: linear-gradient(0deg, #ceab77 20%, #eddbc1 100%);
  color: #000;
  display: flex;
  justify-content: center;
  font-size: 0.3375rem;
}

/deep/.van-popup__close-icon--top-right {
  top: 0.15rem;
  right: 16px;
}

/deep/.van-popup__close-icon {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.van-action-sheet__item,
.van-action-sheet__cancel {
  color: #000;
  background-color: #ffffff;
}
</style>